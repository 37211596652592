import { useDispatch, useSelector } from "react-redux";

import { modalActions } from "store-redux/modal-slice";
import { NavbarLinks } from "helpers/constants";

import InputForm from "components/forms/input/InputForm";
import HumburgerButton from "components/UI/button/HumburgerButton";
import Signup from "components/modal/sign-up/Signup";
import SignupDetails from "components/modal/sign-up/SignupDetails";
import SignIn from "components/modal/sign-in/SignIn";
import Button from "components/UI/button/Button";

import NavbarList from "./navbar-list/NavbarList";
import NavbarLogo from "./navbar-logo/NavbarLogo";
import SideNavbar from "./side-navbar/SideNavbar";

import classes from "./Navbar.module.css";
import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useWindowWidth from "helpers/useWindowWidth";
import Backdrop from "components/UI/modal/Backdrop";
import { Input, Menu, Select } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { API_BASE_URL, APP_PREFIX_PATH } from "configs/AppConfig";
import { useTranslation } from "react-i18next";
import VerifyEmail from "components/modal/verify-email/VerifyEmail";

// Navbar component => the Parent Component for the Navbar elements
// all the navbar related components are child for this component
let emailValue = "";
const { Option } = Select;

const Navbar = ({ isAuthLoading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { modalIsVisible, modalType } = useSelector((state) => state.modal);
  const { user, isLoggedIn } = useSelector((state) => state.user);
  const { language, direction } = useSelector((state) => state.language);

  const location = useSelector((state) => state.filter);
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [categoryData, setCategoryData] = useState([]);
  const [filterData, setFilterData] = useState({
    // latitude: "",
    // longitude: "",
    range: undefined,
    categoryId: undefined,
  });
  const [searchTerm, setSearchTerm] = useState("");

  console.log("modal type is", modalType);

  const [eventData, setEventData] = useState([]);

  const locationValue = useLocation();

  // useEffect(()=>{
  // const filterValues = JSON.parse(localStorage.getItem("wedding_filter"))
  // console.log('filter from local', filterValues)
  // setFilterData(filterValues);
  // },[])

  console.log("location in navbar", location);

  const languageValues = [
    { label: "English", value: "en" },
    { label: "Arabic", value: "ar" },
    { label: "Hebrew", value: "he" },
  ];

  useEffect(() => {
    if (locationValue.pathname.includes("/search")) {
      setIsSearchOpen(true);
    } else {
      setIsSearchOpen(false);
    }
  }, [locationValue.pathname]);

  const { t } = useTranslation();

  const width = useWindowWidth();
  const isBigScreen = width > 1024;

  //----------Login modals and buttons in navbar --------
  // const setSigninModalHandler = () => {
  //   dispatch(
  //     modalActions.changeModalComponent({
  //       modalType: 'signin'
  //     })
  //   );
  // };

  // const setSignupDetailsModalHandler = (value) => {
  //   emailValue = value;
  //   dispatch(
  //     modalActions.changeModalComponent({
  //       modalType: "signupDetails",
  //     })
  //   );
  // };

  const setVerifyEmailHandler = () => {
    dispatch(
      modalActions.changeModalComponent({
        modalType: "verifyEmail",
      })
    );
  };

  const checkLoggedIn = ({ redirect }) => {
    if (isLoggedIn) {
      if (width <= 1024) toggleModalHandler(); //to close mobile sidebar
      navigate(redirect);
      return;
    } else {
      setVerifyEmailHandler();
      return;
    }
  };

  const articleMenu = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            if (width <= 1024) toggleModalHandler();
            navigate(
              "/advanced-search-article?DataType=article&storyStatus=live&page=1&limit=10"
            );
          }}
        >
          {t("articles")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => checkLoggedIn({ redirect: "/my-account/add-article" })}
        >
          {t("add_article")}
        </div>
      ),
    },
    {
      key: "3",
      label: (
        <div
          onClick={() => {
            if (width <= 1024) toggleModalHandler();
            navigate(
              "/advanced-search-historical?DataType=article&storyStatus=historical&page=1&limit=10"
            );
          }}
        >
          {t("historical_articles2")}
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: (
    //     <div
    //       onClick={() =>
    //         checkLoggedIn({ redirect: "/my-account/suggest-an-article" })
    //       }
    //     >
    //       {t("suggest_an_article")}
    //     </div>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: (
    //     <div
    //       onClick={() => {
    //         if (width <= 1024) toggleModalHandler();
    //         navigate("/gallery");
    //       }}
    //     >
    //       {t("gallery")}
    //     </div>
    //   ),
    // },
    // {
    //   key: "5",
    //   label: (
    //     <div
    //       onClick={() => checkLoggedIn({ redirect: "/my-account/add-gallery" })}
    //     >
    //       {t("add_gallery")}
    //     </div>
    //   ),
    // },
  ];

  const galleryMenu = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            if (width <= 1024) toggleModalHandler();
            navigate("/gallery");
          }}
        >
          {t("gallery")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() => checkLoggedIn({ redirect: "/my-account/add-gallery" })}
        >
          {t("add_gallery")}
        </div>
      ),
    },
  ];

  const factCheckMenu = [
    {
      key: "1",
      label: (
        <div
          onClick={() => {
            if (width <= 1024) toggleModalHandler();
            navigate("/fact-checked");
          }}
        >
          {t("debunked")}
        </div>
      ),
    },
    {
      key: "2",
      label: (
        <div
          onClick={() =>
            checkLoggedIn({ redirect: "/my-account/add-debunk-article" })
          }
        >
          {t("add_debunk_article")}
        </div>
      ),
    },
  ];

  const setSideNavbarIsOpenHandler = () => {
    if (modalType === "sidenav") {
      dispatch(modalActions.toggleModal());
    } else {
      dispatch(
        modalActions.changeModalComponent({
          modalType: "sidenav",
        })
      );
    }
  };

  const closeModalHandler = () => {
    if (modalIsVisible) {
      dispatch(modalActions.toggleModal());
    }
  };

  const toggleModalHandler = () => {
    // toggle the visability of the Modal
    dispatch(modalActions.toggleModal());
  };

  console.log("does console come here");

  //----------Modal components corresponding to required functionality------
  const renderModal = () => {
    if (modalIsVisible) {
      if (modalType === "signup") {
        return (
          <Signup
            // showSignupDetailsModal={setSignupDetailsModalHandler}
            onClose={toggleModalHandler}
          />
        );
      }

      if (modalType === "signupDetails") {
        return (
          <SignupDetails emailValue={emailValue} onClose={toggleModalHandler} />
        );
      }

      if (modalType == "signin") {
        console.log("rendering done");
        return <SignIn onClose={toggleModalHandler} />;
      }

      if (modalType == "verifyEmail") {
        return <VerifyEmail onClose={toggleModalHandler} />;
      }

      if (modalType === "sidenav" && !isBigScreen) {
        return (
          <>
            <SideNavbar
              onClose={toggleModalHandler}
              NAVBAR_LINKS={NAVBAR_LINKS}
              languageValues={languageValues}
            />
            <Backdrop onClose={toggleModalHandler} />
          </>
        );
      }
    }

    return;
  };

  const NAVBAR_LINKS = [
    {
      type: "link",
      to: APP_PREFIX_PATH + "/",
      title: t("home"),
    },

    {
      type: "menu",
      to:
        APP_PREFIX_PATH +
        `/advanced-search-article?DataType=article&storyStatus=live&page=1&limit=10`,
      title: t("articles"),
      menu: articleMenu,
    },
    {
      type: "link",
      to: APP_PREFIX_PATH + "/categories",
      title: t("categories"),
    },
    {
      type: "menu",
      to: APP_PREFIX_PATH + "/fact-checked",
      title: t("debunked"),
      menu: factCheckMenu,
    },
    {
      type: "menu",
      to: APP_PREFIX_PATH + "/gallery",
      title: t("gallery"),
      menu: galleryMenu,
    },
    // {
    //   type: "link",
    //   to: `/advanced-search-historical?DataType=article&storyStatus=historical&page=1&limit=10`,
    //   title: t("historical_articles2"),
    // },
    // {
    //   type: "link",
    //   to: `/informations/resources`,
    //   title: t("resources"),
    //   openInNewTab: true,
    // },
    {
      type: "link",
      to: `/informations/about-us`,
      title: t("about_us"),
      openInNewTab: true,
    },
  ];

  const searchIconFunction = () => {
    if (width >= 1020) {
      setIsSearchOpen(true);
    } else {
      navigate(`${APP_PREFIX_PATH}/search`);
    }
  };

  const constructQueryString = (filterData) => {
    const queryParams = [];
    for (const key in filterData) {
      if (filterData[key] !== "" && filterData[key] !== undefined) {
        queryParams.push(
          `${encodeURIComponent(key)}=${encodeURIComponent(filterData[key])}`
        );
      }
    }
    return queryParams.join("&");
  };

  // useEffect(()=>{
  //   if(window.location.pathname.includes('/search')){
  //     console.log('entered here')
  //   handleClick();
  //   }

  // },[location])

  const handleClick = () => {
    const queryString = constructQueryString(filterData);
    const baseUrl = "http://localhost:3000/search"; // Replace 'your_base_url_here' with your actual base URL
    const finalUrl = queryString ? `${baseUrl}?${queryString}` : baseUrl;
    localStorage.setItem("wedding_filter", JSON.stringify(filterData));
    if (window.location.pathname.includes("/search")) {
      navigate(`search/?${queryString}`);
    } else {
      navigate(`/search?${queryString}`);
    }
  };

  const fetchEvents = async () => {
    const response = await fetch(`${API_BASE_URL}eventType/get/all`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        // Authorization: `Bearer ${user.sessionToken}`,
      },
      // body: JSON.stringify(),
    });

    const data = await response.json();
    setEventData(data.data);
    console.log("response", data);
    if (!response.ok) {
      // message.error(data.message ? data.message : "Invalid Email or Password");
      throw new Error(data.message);
    }

    return data;
  };

  // useEffect(() => {
  //   fetchEvents();
  // }, [user]);

  // useEffect(() => {
  //  if(!window.location.pathname.includes('/search')){
  //   setFilterData({
  //     range: undefined,
  //     categoryId: undefined,
  //   })
  //  }
  // }, [window.location.pathname]);

  const filterDataChange = (name, value) => {
    setFilterData({ ...filterData, [name]: value });
  };

  console.log("filter data in navbar", filterData);

  const handlePressEnter = () => {
    navigate(`/advanced-search-mobile?page=1&limit=10`);
  };

  return (
    <div className={`${classes.mainNavbar}`}>
      <div className={classes.navbarGroup}>
        <NavbarLogo onClick={closeModalHandler} />
        {!isBigScreen && (
          <div className={classes.burgerSearchIcons}>
            <img
              src="/news-img/icons/mob-search.svg"
              className={classes.searchIcon}
              onClick={handlePressEnter}
            />
            <HumburgerButton
              className={classes.humburgerButton}
              onClick={setSideNavbarIsOpenHandler}
              open={modalIsVisible && modalType === "sidenav"}
            />
          </div>
        )}
      </div>
      {isBigScreen && (
        <div className={classes.navbarList}>
          <NavbarList
            isSearchOpen={isSearchOpen}
            languageValues={languageValues}
            filterDataChange={filterDataChange}
            categoryData={categoryData}
            filterData={filterData}
            eventData={eventData}
            NAVBAR_LINKS={NAVBAR_LINKS}
            isAuthLoading={isAuthLoading}
          />
        </div>
      )}
      {renderModal()}
    </div>
  );
};

export default Navbar;
